import React, { useState } from 'react';
import Icon from '../general/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faLock, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { DebounceInput } from 'react-debounce-input';
import useCollections from '../../hooks/useCollections';

const CollectionHeader = ({collection}) => {
    console.log("collection: ", collection);
    const [showPrivacyBox, setShowPrivacyBox] = useState(false);
    const [saving, setSaving] = useState(false);
    const togglePrivacyBox = () => {
        setShowPrivacyBox(!showPrivacyBox);
    }

    const {saveChangesToCollection} = useCollections();

    const privacyOptionChanged = async (e) => {
        console.log(e.target.value);
        let newPrivacyOption = e.target.value;
        if (newPrivacyOption === "true") {newPrivacyOption = true}
        if (newPrivacyOption === "false") {newPrivacyOption = false}
        setSaving(true);
        await saveChangesToCollection({display: newPrivacyOption, docId: collection.docId})
        setSaving(false);
    }

    const renameCollectionNameOnInput = async (e) => {
        console.log(e.target.value);
        setSaving(true);
        await saveChangesToCollection({title: e.target.value.trim(), docId: collection.docId});
        setSaving(false);
    }
    return (
        <div>
            <div className="flex flex-col gap-4">
            <div className="pl-2 flex w-full flex-col gap-4 justify-start place-items-start">
                <div className="flex flex-row gap-4 justify-start place-items-start">
                    <div className="text-xl relative">
                        {collection !== null &&
                            <>
                                <div onClick={togglePrivacyBox}>
                                    {(collection.display === false) && 
                                        <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faLock} />} color="charcoal" hover="none" cursor="pointer" /> 
                                    }
                                    {(collection.display === true) && 
                                        <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faGlobe} />} color="charcoal" hover="none" cursor="pointer" /> 
                                    }
                                </div>
                                {showPrivacyBox &&
                                    <>
                                    <div className="absolute left-0 top-8 bg-white dark:bg-gray-800 rounded-xl w-48 shadow-xl p-5 z-20">
                                        <fieldset>
                                            <div className="flex flex-col gap-3">
                                                {/* Private Option */}
                                                <div>
                                                    <div className="flex flex-row gap-2 font-bold text-base text-gray-700 dark:text-gray-300">
                                                        <input
                                                            onChange={privacyOptionChanged}
                                                            type="radio"
                                                            id="private"
                                                            value={false}
                                                            checked={collection.display === false}
                                                        />
                                                        <label htmlFor="private">Private</label>
                                                    </div>
                                                    <div className="text-xs text-gray-500 dark:text-gray-400">
                                                        Only you can see this collection.
                                                    </div>
                                                </div>

                                                {/* Public Option */}
                                                <div>
                                                    <div className="flex flex-row gap-2 font-bold text-base text-gray-700 dark:text-gray-300">
                                                        <input
                                                            onChange={privacyOptionChanged}
                                                            type="radio"
                                                            id="public"
                                                            value={true}
                                                            checked={collection.display === true}
                                                        />
                                                        <label htmlFor="public">Public</label>
                                                    </div>
                                                    <div className="text-xs text-gray-500 dark:text-gray-400">
                                                        The collection is displayed on the language pages.
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div onClick={togglePrivacyBox} className="z-10 w-screen h-screen fixed left-0 top-0 ">

                                    </div>
                                    </>
                                }
                            </>
                        }
                        {collection === null &&
                            <>
                                <Skeleton count={1} height={30} />
                            </>
                        }
                    </div>
                    <div className="">
                        <div className="font-bold muted-text text-lg">
                        {collection !== null ? 
                        <DebounceInput
                            className={" text-left dark:text-white hover:underline bg-transparent font-semibold border-none outline-none"}
                            minLength={1}
                            value={collection.title}
                            debounceTimeout={1000}
                            onChange={event => renameCollectionNameOnInput(event)} 
                        /> : 
                        <Skeleton count={1} height={30} />}
                        </div>
                        
                    </div>
                    <div>
                        {saving && <FontAwesomeIcon icon={faSpinner} spin />}
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default CollectionHeader;