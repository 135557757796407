import React, { useEffect, useState } from 'react';
import MultilingualDeckHeader from '../../components/decks/MultilingualDeckHeader';
import useDecks from '../../hooks/useDecks';
import { useParams } from 'react-router-dom';
import MultiLingualDeckTable from '../../components/decks/MultilingualDeckTable';

const MultilingualDeckPage = () => {
    let { id } = useParams();
    id = parseInt(id);
    const {fetchDeckFromCurrentUserRealtime, 
        fetchAllItemsInSingleDeck
    } = useDecks();

    const [deck, setDeck] = useState(null);
    const [dbCards, setDbCards] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    //        await fetchDeckFromCurrentUserRealtime(props.deck_id, setDeck, setError);

    useEffect(()=>{
        let unsubscribe = null;
        const fetchData = async () => {
            unsubscribe = await fetchDeckFromCurrentUserRealtime(id, setDeck, setError);
            const cards_ = await fetchAllItemsInSingleDeck(id);
            setDbCards(cards_["items"]);
            setLoading(false);
        }
        fetchData();

        return () => {
            if (unsubscribe !== null){
                unsubscribe();
            } 
        }
    },[id]);

    return (
        <div>
            {!loading && 
                <>
                    {deck &&
                        <div className="flex flex-col gap-10">
                            <MultilingualDeckHeader deck={deck} />  
                            <MultiLingualDeckTable deck={deck} dbCards={dbCards} />
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default MultilingualDeckPage;