import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faNote, faChartSimple, faCalendarDays, faCardsBlank, faPercentage, faAlarmClock, faBrain} from '@fortawesome/pro-duotone-svg-icons';
import {faBlender} from '@fortawesome/pro-duotone-svg-icons';
import {faBraille} from '@fortawesome/pro-duotone-svg-icons';
import {faBallotCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faMagnifyingGlass} from '@fortawesome/pro-duotone-svg-icons';
import {faBookOpenReader} from '@fortawesome/pro-duotone-svg-icons';
import {faPenField} from '@fortawesome/pro-duotone-svg-icons';
import useStatistics from '../../hooks/useStatistics';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';

const LifetimeAnalyticsCard = (props) => {

    const {language, timePeriod, type} = props;
    let { activeUserLanguages, allLanguages } = useLanguagesContext();
    const {getQuizStatisticsForCurrentUserInTargetLanguage, 
        getFlashcardStatisticsForCurrentUserInTargetLanguage, 
        getInputStatisticsForCurrentUserInTargetLanguage} = useStatistics();
    const [quizData, setQuizData] = useState({});
    const [flashcardsData, setFlashcardsData] = useState({});
    const [inputData, setInputData] = useState({});
    const [totalData, setTotalData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const fetchData = async () => {
            let r_q = await getQuizStatisticsForCurrentUserInTargetLanguage(language);
            if (!r_q){return}
            setQuizData({[`${language}`]: r_q});
            let r_f = await getFlashcardStatisticsForCurrentUserInTargetLanguage(language);
            setFlashcardsData({[`${language}`]: r_f});
            let r_i = await getInputStatisticsForCurrentUserInTargetLanguage(language);
            setInputData({[`${language}`]: r_i});
            setLoading(false);
            console.log("lifetime: ", r_q, r_f, r_i);
            setTotalData({[`${language}`]: {
                'number_of_answers': (((r_q !== null && r_q.hasOwnProperty("number_of_answers")) ? r_q['number_of_answers'] : 0) + ((r_f !== null && r_f.hasOwnProperty("number_of_answers")) ? r_f['number_of_answers'] : 0) + ((r_f !== null && r_i.hasOwnProperty("number_of_answers")) ? r_i['number_of_answers'] : 0)), 
                'number_of_correct_answers': (((r_q !== null && r_q.hasOwnProperty("number_of_correct_answers")) ? r_q['number_of_correct_answers'] : 0) + ((r_f !== null && r_f.hasOwnProperty("number_of_correct_answers")) ? r_f['number_of_correct_answers'] : 0) + ((r_f !== null && r_i.hasOwnProperty("number_of_correct_answers")) ? r_i['number_of_correct_answers'] : 0)),
                'number_of_wrong_answers': (((r_q !== null && r_q.hasOwnProperty("number_of_wrong_answers")) ? r_q['number_of_wrong_answers'] : 0) + ((r_f !== null && r_f.hasOwnProperty("number_of_wrong_answers")) ? r_f['number_of_wrong_answers'] : 0) + ((r_f !== null && r_i.hasOwnProperty("number_of_wrong_answers")) ? r_i['number_of_wrong_answers'] : 0)),
            }});
        }

        if (language !== null){
            fetchData();
        }

    },[language]);

    return (
<>
<div className="w-full max-w-3xl mx-auto">
    {!loading && (
        <>
            {/* Title */}
            <div className="font-semibold text-lg lg:text-lg text-center text-gray-700 dark:text-gray-200 capitalize">
                Correct Exercises for
                {allLanguages !== null && language !== null && (
                    <> {allLanguages[0][language]["name"]["en"]}</>
                )}
            </div>

            {/* Analytics Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
                {/* Total */}
                {totalData !== null && totalData.hasOwnProperty(language) && (
                    <div className="flex flex-col items-center bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform hover:scale-105">
                        <div className="text-3xl text-charcoal dark:text-persian-green mb-2">
                            <FontAwesomeIcon icon={faChartSimple} />
                        </div>
                        <div className="text-gray-600 dark:text-gray-300 font-semibold">Total Answers</div>
                        <div className="text-lg font-bold text-gray-800 dark:text-gray-100">
                             {new Intl.NumberFormat().format(totalData[language]?.number_of_correct_answers || 0)} / 
                            {new Intl.NumberFormat().format(totalData[language]?.number_of_answers || 0)}
                        </div>
                    </div>
                )}

                {/* Flashcards */}
                {flashcardsData !== null && flashcardsData.hasOwnProperty(language) && (
                    <div className="flex flex-col items-center bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform hover:scale-105">
                        <div className="text-3xl text-sandy-brown mb-2">
                            <FontAwesomeIcon icon={faNote} />
                        </div>
                        <div className="text-gray-600 dark:text-gray-300 font-semibold">Flashcards</div>
                        <div className="text-lg font-bold text-gray-800 dark:text-gray-100">
                             {new Intl.NumberFormat().format(flashcardsData[language]?.number_of_correct_answers || 0)} / 
                            {new Intl.NumberFormat().format(flashcardsData[language]?.number_of_answers || 0)}
                        </div>
                    </div>
                )}

                {/* Quiz */}
                {quizData !== null && quizData.hasOwnProperty(language) && (
                    <div className="flex flex-col items-center bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform hover:scale-105">
                        <div className="text-3xl text-persian-green mb-2">
                            <FontAwesomeIcon icon={faBallotCheck} />
                        </div>
                        <div className="text-gray-600 dark:text-gray-300 font-semibold">Quiz</div>
                        <div className="text-lg font-bold text-gray-800 dark:text-gray-100">
                            {new Intl.NumberFormat().format(quizData[language]?.number_of_correct_answers || 0)} / 
                            {new Intl.NumberFormat().format(quizData[language]?.number_of_answers || 0)}
                        </div>
                    </div>
                )}

                {/* Input */}
                {inputData !== null && inputData.hasOwnProperty(language) && (
                    <div className="flex flex-col items-center bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform hover:scale-105">
                        <div className="text-3xl text-burnt-sienna mb-2">
                            <FontAwesomeIcon icon={faPenField} />
                        </div>
                        <div className="text-gray-600 dark:text-gray-300 font-semibold">Input</div>
                        <div className="text-lg font-bold text-gray-800 dark:text-gray-100">
                            {new Intl.NumberFormat().format(inputData[language]?.number_of_correct_answers || 0)} / 
                            {new Intl.NumberFormat().format(inputData[language]?.number_of_answers || 0)}
                        </div>
                    </div>
                )}
            </div>
        </>
    )}
</div>

</>

    )
}

export default LifetimeAnalyticsCard;