import React, { useEffect, useState } from 'react';
import useDecks from '../../hooks/useDecks';
import useCourses from '../../hooks/useCourses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

const ImageComponent = (props) => {
    const {value, onChange, uploadedThumbnailImage, loading} = props;
    const preview = (uploadedThumbnailImage !== null && uploadedThumbnailImage !== undefined) ? URL.createObjectURL(uploadedThumbnailImage) : (value !== null && value !== undefined) ? value : null;
    
    return (
        <>
            <div className="flex flex-row gap-6 place-items-center">
                <div className="rounded-2xl bg-white w-24 shadow-xl overflow-hidden flex flex-col justify-center">
                    {preview!== null && preview !== undefined ?
                        <>
                            <img src={preview} alt="thumbnail" />
                        </>
                        :
                        <>
                            <div className="p-4">
                                No thumbnail
                            </div>
                        </>
                    }
                    </div>
                <input type="file" onChange={(e)=>onChange(e)} accept=".jpg,.jpeg,.png,.webp" />
                {loading &&
                    <>
                        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                    </>
                }
            </div>
        </>
    )

}

const MultilingualDeckThumbnail = ({deck}) => {

    const {uploadDeckThumbnail} = useDecks();
    const {getFullThumbnailPath} = useCourses();

    const [thumbnailImage, setThumbnailImage] = useState("");
    const [uploadedThumbnailImage, setUploadedThumbnailImage] = useState(null);
    const [thumbnailUploading, setThumbnailUploading] = useState(false);

    useEffect(()=>{
        if (deck !== null && deck !== undefined){
            if (deck.hasOwnProperty("thumbnails_200x200")){
                let fullUrl = getFullThumbnailPath({dbPath: deck['thumbnails_200x200']["default"]});
                console.log("Full url: ", fullUrl);
                setThumbnailImage(fullUrl);
            };
        }
    },[deck]);  

    const thumbnailImageOnChange = async (e) => {
        console.log("Handling thumbnail file change");
        setUploadedThumbnailImage(e.target.files[0]);
        console.log("thumbnail file: ", e.target.files[0]);
        //await uploadCourseThumbnailClick(course, e.target.files[0]);
        let file = e.target.files[0];
        await uploadThumbnailToServer(file);
    };

    const uploadThumbnailToServer = async (file) => {
        console.log("Uploading thumbnail: ", file);
        setThumbnailUploading(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1];
        const contentType = file.type;
        await uploadDeckThumbnail({base64Image, file, contentType, deck, multilingual:true});
        setThumbnailUploading(false);
        };
    };

    return (
        <div>
            <ImageComponent value={thumbnailImage} onChange={thumbnailImageOnChange} uploadedThumbnailImage={uploadedThumbnailImage} loading={thumbnailUploading} />
        </div>
    )
}

export default MultilingualDeckThumbnail;