import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus, faSave, faBars } from '@fortawesome/pro-solid-svg-icons';
import useCollections from '../../hooks/useCollections';
import useDecks from '../../hooks/useDecks';
import Button from '../general/Button';

const CollectionDecks = ({ collection }) => {
    console.log("collection: ", collection);
    const [saving, setSaving] = useState(false);
    const [newDeckId, setNewDeckId] = useState('');
    const [availableDecks, setAvailableDecks] = useState([]);
    const [deckList, setDeckList] = useState(collection.decks || []);
    const { saveChangesToCollection } = useCollections();
    const { getAllMultilingualDecksFromUser } = useDecks();

    useEffect(() => {
        const fetchDecks = async () => {
            const decks = await getAllMultilingualDecksFromUser();
            setAvailableDecks(decks);
        };
        fetchDecks();
    }, [getAllMultilingualDecksFromUser]);

    const addDeckToList = () => {
        if (!newDeckId.trim()) return;
        setDeckList([...deckList, parseInt(newDeckId.trim())]);
        setNewDeckId('');
    };

    const saveDecks = async () => {
        setSaving(true);
        await saveChangesToCollection({ decks: deckList, docId: collection.docId });
        setSaving(false);
    };

    const handleDragStart = (index) => (event) => {
        event.dataTransfer.setData("text/plain", index);
    };

    const handleDrop = (index) => (event) => {
        event.preventDefault();
        const draggedIndex = event.dataTransfer.getData("text/plain");
        const updatedDecks = [...deckList];
        const [movedDeck] = updatedDecks.splice(draggedIndex, 1);
        updatedDecks.splice(index, 0, movedDeck);
        setDeckList(updatedDecks);
    };

    return (
        <div className="flex flex-col gap-4">
            <h1>Decks in collection</h1>
            <div className="pl-2 flex w-full flex-col gap-4 justify-start place-items-center">
                <div className="flex flex-row gap-4 justify-start place-items-start">
                    <select
                        className="text-left dark:text-white bg-transparent font-semibold border rounded p-2 outline-none"
                        value={newDeckId}
                        onChange={(e) => setNewDeckId(e.target.value)}
                    >
                        <option value="" disabled>Select a deck</option>
                        {availableDecks.filter((deck)=>!deckList.includes(deck.id)).map((deck) => (
                            <option key={deck.id} value={deck.id}>{deck.name}</option>
                        ))}
                    </select>
                    <button 
                        className="bg-persian-green text-white px-4 py-2 rounded"
                        onClick={addDeckToList}
                        disabled={!newDeckId}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                    <ul className="mt-4">
                        {deckList.map((deckId, index) => (
                            <li 
                                key={index} 
                                className="flex items-center gap-2 p-2 border rounded cursor-move"
                                draggable 
                                onDragStart={handleDragStart(index)}
                                onDragOver={(event) => event.preventDefault()}
                                onDrop={handleDrop(index)}
                            >
                                #{index+1} <FontAwesomeIcon icon={faBars} /> {availableDecks.find(deck => deck.id === parseInt(deckId))?.name}
                            </li>
                        ))}
                    </ul>
                </div>
                <Button                    
                    onClick={saveDecks}
                    text="Save changes"
                    color="green"
                    disabled={saving}
                    disabledDesign={saving}
                />
                {saving && <FontAwesomeIcon icon={faSpinner} spin />}
            </div>
        </div>
    );
};

export default CollectionDecks;
