import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCollections from '../../hooks/useCollections';
import CollectionHeader from '../../components/collections/CollectionHeader';
import CollectionSlug from '../../components/collections/CollectionSlug';
import CollectionLanguages from '../../components/collections/CollectionLanguages';
import CollectionDecks from '../../components/collections/CollectionDecks';
import CollectionOrder from '../../components/collections/CollectionOrder';

const LanguageCollectionPage = () => {
    let { docId } = useParams();
    const {fetchCollectionByDocIdInRealtime} = useCollections();
    const [collection, setCollection] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        let unsubscribe = null;
        const fetchData = async () => {
            setLoading(true);
            unsubscribe = await fetchCollectionByDocIdInRealtime({docId: docId, setCollection:setCollection});
            setLoading(false);
        }
        fetchData();
        return () => {
            if (unsubscribe !== null){
                unsubscribe();
            } 
        }
    }
    ,[docId]);

    return (
        <div className="flex flex-col gap-12">
        {!loading && 
            <>
                {collection &&
                    <>
                    <div className="flex flex-row place-items-center gap-10">
                        <CollectionHeader collection={collection} />
                        <CollectionSlug collection={collection} />
                        <CollectionOrder collection={collection} />
                    </div>
                    <div className="bg-white rounded-xl shadow-xl p-6">
                        <CollectionLanguages collection={collection} />
                    </div>
                    <div className="bg-white rounded-xl shadow-xl p-6">
                        <CollectionDecks collection={collection} />
                    </div>
                    </>
                }
            </>
        }
    </div>
    )
}

export default LanguageCollectionPage;