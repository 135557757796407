import { httpsCallable } from 'firebase/functions';
import { projectFunctions } from '../firebase/config';

const useAI = () => {

    const generateAICardsForDeck = async ({prompt}) => {
        const f = httpsCallable(projectFunctions, "generateAICardsForDeck");//cloud function
        try {
            let r = await f({prompt: prompt});
            console.log("r: ", r);
            return r["data"];
        } catch (error) {
            console.log("error: ", error);
            return null;
        }
    }

    return {
        generateAICardsForDeck
    }
}

export default useAI;