import { Link } from "react-router-dom";
import React from 'react';
import {motion} from 'framer-motion';

const CollectionCard = (props) =>  {
    const {collection} = props;

    return (
        <>
            {collection &&
            <>
                <div className="h-full w-full">
                    <Link className="no-underline h-full w-full" to={"/my-language-collections/"+collection.docId}>
                        <motion.div  whileHover={{y:-3}} className={"relative shadow-lg rounded-lg w-full h-full p-4  w-full bg-white  text-black shadow-lg cursor-pointer relative border border-solid border-gray-100 flex flex-col justify-evenly"}>
                            <div className="flex absolute  p-2  left-1 top-1 flex-row place-items-center justify-start gap-5">
                                <div className="flex flex-row place-items-center text-charcoal gap-1  ">
                                    <div className="font-bold text-sm">
                                        Collection
                                    </div>
                                </div>
                            
                            </div>
                            <div className="p-4 max-h-48 overflow-auto gap-4 flex flex-col">
                                <div className="text-base text-center font-bold flex flex-row justify-center gap-2">
                                    <div>
                                        {collection.title}
                                    </div>
                                </div>

                            </div>
                        </motion.div>
                    </Link>
                </div>
                </>
            }
        </>
    )
}

export default CollectionCard;