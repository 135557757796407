import {projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast
} from "firebase/firestore";
import {  httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { useAuth } from "../contexts/AuthContext";
import useUserSettings from './useUserSettings';
import useQueryStrings from './useQueryStrings';
import { faTrumpet } from '@fortawesome/pro-duotone-svg-icons';
import { useCallback } from 'react';
import useLogs from './useLogs.js';

export default function useDecks() {

    if (process.env.NODE_ENV === 'development') {
        connectFunctionsEmulator(projectFunctions, "localhost", 5001);
    } 

    const {currentUser} = useAuth();
    const {fetchCurrentUserInfo} = useUserSettings();
    const {getQueryStrings} = useQueryStrings();

    const {saveErrorLog} = useLogs();

    const newCardStructureBool = true;


    const uploadMultipleCardsToDeckApi = async (deckId, listOfCards, firstColOption, secondColOption, deck, ytId) => {
        let f = httpsCallable(projectFunctions, 'uploadMultipleCardsToDeckApi');
        let args = {'deckId': deckId, 'listOfCards': listOfCards, 'firstColOption':firstColOption, 'secondColOption':secondColOption, 'deck':deck};
        if (ytId !== undefined && ytId !== null){
            args['ytId'] = ytId;
        }
        let r = await f(args).catch(err=>{
            saveErrorLog({errorObject: err, functionName: "uploadMultipleCardsToDeckApi"});
        });
        return r['data'];
    }


    const fetchAllItemsInSingleDeck = async (deck_id, public_deck) => {
        let {items, deck} = await fetchAllItemsInDeck(deck_id, public_deck).catch(err => {
            saveErrorLog({errorObject: err, functionName: "fetchAllItemsInSingleDeck"});
        });
        return {items: items, deck: deck};
    }
    
    const getDeckAndXRandomCardsFromDeckApi = async (deckId, howManyItems, game) => {
        let f = httpsCallable(projectFunctions, 'getDeckAndXRandomCardsFromDeck');
        let r = await f({'deck_id': deckId, 'howManyItems': howManyItems, 'game': game}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getDeckAndXRandomCardsFromDeckApi"});
        });
        return r?.data;
    }

    const getAllMultilingualDecksFromUser = useCallback(async () => {
        //client firebase
        const colRef = collection(projectFirestore, "decks");
        const q = query(colRef, where("uid", "==", currentUser.uid), where("multilingual", "==", true));
        const snapshot = await getDocs(q).catch((err) => {
            saveErrorLog({errorObject: err, functionName: "getAllMultilingualDecksFromUser"});
        });
        let decks = [];
        snapshot.forEach((doc) => {
            decks.push({ ...doc.data(), doc_id: doc.id });
        });
        return decks;
    },[]);

    const uploadDeckThumbnail = useCallback(async ({ base64Image, file, contentType, deck, multilingual=false }) => {
        const uploadImage = httpsCallable(projectFunctions, 'uploadDeckThumbnail');
    
        try {
            const result = await uploadImage({
                base64Image,
                fileName: file.name,
                contentType: contentType,
            });
            console.log('Upload successful:', result);
            const fileName = result.data.fileName;
            const deckDocId = deck.doc_id;
            const deckRef = doc(projectFirestore, "decks", deckDocId);
            console.log("deck doc Id: ", deckDocId);
            const dbPath200x200 = `images/decks/thumbnails/thumbnails_200x200/${fileName.split(".")[0]}_200x200.webp`; // Converted to webp
            const dbPath2000x2000 = `images/decks/thumbnails/thumbnails_2000x2000/${fileName.split(".")[0]}_2000x2000.webp`; // Converted to webp
    
            if (!multilingual){
                await updateDoc(deckRef, {
                    thumbnail_200x200: dbPath200x200,
                    thumbnail_2000x2000: dbPath2000x2000,
                    last_updated_timestamp: timeStamp,
                }, { merge: true });
            } else {
                //not same db field
                await updateDoc(deckRef, {
                    thumbnails_200x200: {default: dbPath200x200},
                    thumbnails_2000x2000: {default: dbPath2000x2000},
                    last_updated_timestamp: timeStamp,
                }, { merge: true });
            }
    
            return dbPath200x200;
    
        } catch (error) {
            console.error('Error uploading file: ', error);
            return null;
        }
    }, []);
    

    const getDeckCardsForCourseLessonApi = async ({deckId, howManyItems, game}) => {
        let f = httpsCallable(projectFunctions, 'getDeckAndXRandomCardsFromDeck');
        let r = await f({'deck_id': deckId, 'howManyItems': howManyItems, 'game': game, 'type': 'course'}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getDeckCardsForCourseLessonApi"});
        });
        return r?.data;
    }

    const getXRandomCardsFromVocabularyApi = async (howManyItems, level, lang) => {
        console.log("level");
        let f = httpsCallable(projectFunctions, 'getXRandomCardsFromVocabulary');
        let r = await f({'level': level, 'howManyItems': howManyItems, 'lang': lang}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getXRandomCardsFromVocabularyApi"});
        });
        return r?.data;
    }
    
    const getXCardsFromVocabularyForSpacedRepetitionApi = async (howManyItems, level, lang) => {
        let f = httpsCallable(projectFunctions, 'getXCardsFromVocabularyForSpacedRepetition');
        let r = await f({'level': level, 'howManyItems': howManyItems, 'lang': lang}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getXCardsFromVocabularyForSpacedRepetitionApi"});
        });
        return r?.data;
    }
    
    const getXMistakesToReviewFromLastYDaysApi = async (howManyItems, days, lang) => {
        let f = httpsCallable(projectFunctions, 'getXMistakesToReviewFromLastYDays');
        let r = await f({'days': days, 'howManyItems': howManyItems, 'lang': lang}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getXMistakesToReviewFromLastYDaysApi"});
        });
        return r?.data;
    }

    const getXRandomCardsFromFolderApi = async (howManyItems, folderDocId) => {
        let f = httpsCallable(projectFunctions, 'getDecksAndXCardsFromFolder');
        let r = await f({'howManyItems': howManyItems, 'folderDocId': folderDocId}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getXRandomCardsFromFolderApi"});
        });
        return r?.data;
    }
    
    const getXRandomItemsFromFolderApi = async (howManyItems, folderDocId) => {
        let f = httpsCallable(projectFunctions, 'getXItemsFromFolder');
        let r = await f({'howManyItems': howManyItems, 'folderDocId': folderDocId}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getXRandomItemsFromFolderApi"});
        });
        return r?.data;
    }
    
    const getXRandomCardsFromAllUserDecksApi = async (howManyItems, lang) => {
        let f = httpsCallable(projectFunctions, 'getXRandomCardsFromCurrentUserDecks');
        let r = await f({'howManyItems': howManyItems, 'lang': lang}).catch(err => {
            saveErrorLog({errorObject: err, functionName: "getXRandomCardsFromAllUserDecksApi"});
        });
        return r?.data;
    }

    const getObjectAndXRandomCardsFromTypeApi = async (id, howManyItems, type, level, lang, game) => {
        if (type === "deck"){
            let r = await getDeckAndXRandomCardsFromDeckApi(id, howManyItems, game).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getDeckAndXRandomCardsFromDeckApi"});
            }); 
            return r;
        } 
        else if (type === "course"){
            let r = await getDeckCardsForCourseLessonApi({deckId: id, howManyItems: howManyItems, game: game}).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getDeckCardsForCourseLessonApi"});
            });
            return r;
        }
    /*     else if (type === "category"){
            let r = await getDecksAndXRandomCardsFromCategoryApi(id, howManyItems).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getDecksAndXRandomCardsFromCategoryApi"});
            });
            return r;
        } 
        else if (type === "section") {
            let r = await getDecksAndXRandomCardsFromSectionApi(id, howManyItems).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getDecksAndXRandomCardsFromSectionApi"});
            });
            return r;
        }  */
        else if (type === "vocabulary"){
            let r = null;
            if (level === "easy" || level === "hard" || level === "normal"){
                r = await getXRandomCardsFromVocabularyApi(howManyItems, level, lang, game).catch(err => {
                    saveErrorLog({errorObject: err, functionName: "getXRandomCardsFromVocabularyApi"});
                });
            } else {
                r = await getXCardsFromVocabularyForSpacedRepetitionApi(howManyItems, level, lang, game).catch(err => {
                    saveErrorLog({errorObject: err, functionName: "getXCardsFromVocabularyForSpacedRepetitionApi"});
                });
            }
            return r;
        } 
        else if (type === "folder-decks"){
            let r = await getXRandomCardsFromFolderApi(howManyItems, id, game).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getXRandomCardsFromFolderApi"});
            });
            return r;
        }
        else if (type === "folder-all"){
            let r = await getXRandomItemsFromFolderApi(howManyItems, id, game).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getXRandomItemsFromFolderApi"});
            });
            return r;
        }
        else if (type === "all-decks"){
            let r = await getXRandomCardsFromAllUserDecksApi(howManyItems, lang, game).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getXRandomCardsFromAllUserDecksApi"});
            });
            return r;
        }
        else if (type === "review-mistakes"){
            let days = 7;
            let r = await getXMistakesToReviewFromLastYDaysApi(howManyItems, days, lang).catch(err => {
                saveErrorLog({errorObject: err, functionName: "getXMistakesToReviewFromLastYDaysApi"});
            });
            return r;
        }
    }


    const refreshObjectAndXRandomCardsFromTypeApi = async (id, howManyItems, type, setLoadingItems, setDeck, setDecks, setCards) => {
        setLoadingItems(true);
        let r = await getObjectAndXRandomCardsFromTypeApi(id, howManyItems, type).catch(err => {
            saveErrorLog({errorObject: err, functionName: "refreshObjectAndXRandomCardsFromTypeApi"});
        });
        'deck' in r && setDeck(r['deck']);
        'decks' in r && setDecks(r['decks']);
        setCards(r['cards']);
    }

    const queryStringsCheckLevel = (setLevel, r) => {
        //helper function
        let level = null;
        let defaultValue = "normal";
        let possible = ['easy', 'normal', 'hard', "spaced-repetition"];
        if ('level' in r && possible.includes(r['level'])){
            try {
                level = r['level'].toString();
                setLevel(r['level'].toString());
            } catch {
                level = defaultValue;
                setLevel(defaultValue);
            }
        }
        else {
            level = defaultValue;
            setLevel(defaultValue);
        }
        return level;
    }

    const queryStringsCheckNumberOfItems = (setHowManyItems, r) => {
        //helper function
        let nbOfItems = null;
        let defaultValue = 25;
        if ('items' in r){
            try {
                nbOfItems = parseInt(r['items']);
                setHowManyItems(parseInt(r['items']));
            } catch {
                nbOfItems = defaultValue;
                setHowManyItems(defaultValue);
            }
        }
        else {
            nbOfItems = defaultValue;
            setHowManyItems(defaultValue);
        }
        return nbOfItems;
    }

    const chooseRandomPlayModes = (nbOfItems) => {
        let options = ["target_first", "source_first"];
        let chosenList = [];
        let counter = 0;
        while (counter < nbOfItems){
            let random = Math.round(Math.random());
            chosenList.push(options[random]);
            counter++;
        }
        return chosenList;
    }

    const queryStringsCheckMode = async (setPlayMode, r, setPlayModeArray, nbOfItems) => {
        let mode = null;
        let defaultValue = "target_first";
        if ('mode' in r){
            if (r['mode'] === "target"){
                setPlayMode("target_first");
                setPlayModeArray(Array(nbOfItems).fill("target_first").flat());
                mode = "target_first";
            } else if (r['mode'] === "source"){
                setPlayMode("source_first");
                setPlayModeArray(Array(nbOfItems).fill("source_first").flat());
                mode = "source_first";
            } else if (r['mode'] === "mix"){
                //choose randomly
                let chosenList = chooseRandomPlayModes(nbOfItems);
                setPlayModeArray(chosenList);
                setPlayMode("mix");
                mode = "mix";
            }
            else {
                setPlayMode(defaultValue);
                mode = defaultValue;
            }
        }
        else {
            mode = defaultValue;
            setPlayMode(defaultValue);
            setPlayModeArray(Array(nbOfItems).fill(defaultValue).flat());
        }
        console.log("Mode: ", mode);
        return mode;
    }

    const backToPlayPageLinkFunction = (type, setBackToPlayPageLink, howManyItems, id, isPublic, lang, pMode,r, courseId, nextLessonId) => {
        if (howManyItems === null){return null}
        let deckBaseUrl = null;
        let modeUrl = "target";
        if (isPublic === true && type === "deck"){
            deckBaseUrl = "/decks/";
        }
        else {
            deckBaseUrl = "/my-decks/";
        }
        if (pMode === "source_first"){
            modeUrl = "source";
        } else if (pMode === "target_first"){
            modeUrl = "target";
        } else if (pMode === "mix"){
            modeUrl = "mix";
        } 
        else {
            modeUrl = "target";
        }
        if (type === "deck"){
            setBackToPlayPageLink(deckBaseUrl+id+'/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "vocabulary"){
            let url = '/my-vocabulary/play?items='+howManyItems+"&mode="+modeUrl;
            if (r !== undefined && r !== null && r['back'] !== undefined){
                if (r['back'] === "play"){
                    url = "/play";
                }
                if (r['back'] === "dashboard"){
                    url = "/dashboard";
                }
            }   
            setBackToPlayPageLink(url);
        }
        else if (type === "folder-decks"){
            setBackToPlayPageLink('/my-folders/'+id+'/decks/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "folder-all"){
            setBackToPlayPageLink('/my-folders/'+id+'/all/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "all-decks"){
            setBackToPlayPageLink('/my-decks/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "review-mistakes"){
            let url = '/dashboard';
            if (r !== undefined && r !== null && r['back'] !== undefined){
                if (r['back'] === "play"){
                    url = "/play";
                }
            }   
            setBackToPlayPageLink(url);
        }
        else if (type === "course"){
            if (courseId !== null){
                setBackToPlayPageLink('/course/'+courseId);
            }
            else {
                setBackToPlayPageLink('/dashboard');
            }
        }
    }

    const fetchGameDataApiHelper = async (setTargetLanguage, game, setApiFetchStarted,id, nbOfItems, type, setDeck, setDecks, setCards, setLoadingItems, level, lang) => {
        setApiFetchStarted(true);
        let r = await getObjectAndXRandomCardsFromTypeApi(id, nbOfItems, type, level, lang, game).catch(err => {
            saveErrorLog({errorObject: err, functionName: "fetchGameDataApiHelper"});
        });
        'deck' in r && setDeck(r['deck']);
        'decks' in r && setDecks(r['decks']);
        if ('decks' in r && r['decks'].length > 0){
            setTargetLanguage(r['decks'][0]['target_ISO_639-1']);
        } else if ('deck' in r){
            setTargetLanguage(r['deck']['target_ISO_639-1']);
        }
        if (type === "vocabulary"){
            if (game === "mix"){
                setCards(r['cards']);
            }
            else {
                let tmpDeckCardsList = [];
                if (r['cards'] !== null){
                    r['cards'].forEach((tmp_card, index)=>{
                        if (tmp_card.hasOwnProperty("type") && tmp_card['type'] === "deck"){
                            tmpDeckCardsList.push(tmp_card);
                        }
                        else if (tmp_card.hasOwnProperty("deck_name") && tmp_card.hasOwnProperty('deck_id')){
                            //deck card in game outside of vocabulary games
                            tmpDeckCardsList.push(tmp_card);
                        }
                    })
                }
                setCards(tmpDeckCardsList);
            }
        } else {
            setCards(r['cards']);
        }
        setLoadingItems(false);
    }
    

    const loadAllGameData = useCallback(async (setTargetLanguage, courseId, nextLessonId, setHowManyItems, game, type, setBackToPlayPageLink, id, setParamsLoading, setApiFetchStarted, setDeck, setDecks, setCards, setLoadingItems, isPublic, lang, setLevel, setPlayMode, setPlayModeArray) => {
        let level = null;
        let r = await getQueryStrings().catch(err => {
            saveErrorLog({errorObject: err, functionName: "loadAllGameData"});
        });
        let nbOfItems = queryStringsCheckNumberOfItems(setHowManyItems, r);
        let pMode = "target_first";
        if (setPlayMode !== undefined){
            pMode = await queryStringsCheckMode(setPlayMode, r, setPlayModeArray, nbOfItems).catch(err => {
                saveErrorLog({errorObject: err, functionName: "loadAllGameData - queryStringsCheckMode"});
            });
        }
        if (type === "vocabulary"){
            level = queryStringsCheckLevel(setLevel, r);
        }
        backToPlayPageLinkFunction(type, setBackToPlayPageLink, nbOfItems, id, isPublic, lang, pMode, r, courseId, nextLessonId);
        setParamsLoading(false);
        await fetchGameDataApiHelper(setTargetLanguage, game, setApiFetchStarted,id, nbOfItems, type, setDeck, setDecks, setCards, setLoadingItems, level, lang).catch(err => {
            saveErrorLog({errorObject: err, functionName: "loadAllGameData - fetchGameDataApiHelper"});
        });  
    }, []);

    const refreshAllGameData = async (setTargetLanguage, courseId, nextLessonId, setCards, game, setShowLoadingScreen, id, howManyItems, type, setLoadingItems, setApiFetchStarted, setDeck, setDecks, lang, level, playMode, setPlayMode, setPlayModeArray) => {
        setShowLoadingScreen(true);
        setLoadingItems(true);
        if (playMode === "mix"){
            //update random array
            let chosenList = chooseRandomPlayModes(howManyItems);
            setPlayModeArray(chosenList);
        }
        await fetchGameDataApiHelper(setTargetLanguage, game, setApiFetchStarted,id, howManyItems, type, setDeck, setDecks, setCards, setLoadingItems, level, lang).catch(err => {
            saveErrorLog({errorObject: err, functionName: "refreshAllGameData - fetchGameDataApiHelper"});
        });  
        setShowLoadingScreen(false);
    }
    

    const fetchAllItemsInDeck = async (deck_id, public_deck = false) => {
        let deck = null;
        let items = [];
    
        const deckQuery = query(
            collection(projectFirestore, "decks"),
            where(public_deck ? "privacy" : "uid", "==", public_deck ? "public" : currentUser.uid),
            where("id", "==", deck_id)
        );
    
        const snapshot = await getDocs(deckQuery).catch((err) => {
            saveErrorLog({ errorObject: err, functionName: "fetchAllItemsInDeck - deckQuery" });
            return null;
        });
    
        if (snapshot?.empty) return { items, deck };
    
        deck = { ...snapshot.docs[0].data(), doc_id: snapshot.docs[0].id };
    
        const itemsQuery = query(
            collection(projectFirestore, "deck-cards"),
            where(public_deck ? "privacy" : "uid", "==", public_deck ? "public" : currentUser.uid),
            where("deck_id", "==", deck_id)
        );
    
        const itemsSnapshot = await getDocs(itemsQuery).catch((err) => {
            saveErrorLog({ errorObject: err, functionName: "fetchAllItemsInDeck - itemsQuery" });
            return [];
        });
    
        items = itemsSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            deck_id,
            "target_ISO_639-1": deck["target_ISO_639-1"],
            "source_ISO_639-1": deck["source_ISO_639-1"],
        }));
    
        return { items, deck };
    };

    const fetchAllItemsInMultipleDecks = async (deck_ids) => {
        let all_items = [];
        let all_decks = [];
        for await (const deck_id of deck_ids){
            let {items, deck} = await fetchAllItemsInDeck(deck_id).catch(err => {
                saveErrorLog({errorObject: err, functionName: "fetchAllItemsInMultipleDecks - fetchAllItemsInDeck"});
            });
            all_items = [...all_items, ...items];
            if (deck !== null){
                all_decks = [...all_decks, deck];
            }    
        }
        return {items: all_items, decks: all_decks};
    }


    const addItemToDeck = async ({ deck_id, sourceInput, targetInput, customFieldsInput, outputFormat, multilingual=false, multilingualTerms=null }) => {
        if (!multilingual && (!sourceInput?.trim() || !targetInput?.trim())) return null;
        const colRef = collection(projectFirestore, "decks");
        const q = query(colRef, where("uid", "==", currentUser.uid), where("id", "==", deck_id));
        const snapshot = await getDocs(q);
        console.log("Found deck: ", snapshot.docs);
        if (snapshot.empty) return null;
        const deckDocId = snapshot.docs[0].ref.id;
        let deckData = snapshot.docs[0].data();
        let o = {}; 
        if (customFieldsInput !== null && customFieldsInput !== undefined){
            for (const [key, value] of Object.entries(customFieldsInput)){
                o[parseInt(key)] = value.trim();
            }
        }
        const deckCardsRef = collection(projectFirestore, "deck-cards");
        const obj = {
            created_timestamp: serverTimestamp(),
            last_updated_timestamp: serverTimestamp(),
            'custom_fields': o,
        };
        obj['deck_doc_id'] = deckDocId;
        obj['deck_id'] = deck_id;
        obj['uid'] = currentUser.uid;
        if (!multilingual){
            obj['source_ISO_639-1'] = deckData['source_ISO_639-1'];
            obj['target_ISO_639-1'] = deckData['target_ISO_639-1'];
            obj['source'] = sourceInput.trim();
            obj['target'] = targetInput.trim();
        }
        obj['privacy'] = deckData['privacy'];
        obj['author'] = deckData['author'];
        if (multilingual){
            obj["multilingual"] = true;
            obj["terms"] = multilingualTerms;
        }
        const cardSnapshot = await addDoc(deckCardsRef, obj).then((snap)=>{
            return snap;
        }).catch(error => {
            saveErrorLog({errorObject: error, functionName: "addItemToDeck - query.add"});
            return null;
        });
        let returnObject = {};
        if (!multilingual){
            if (outputFormat === "typesense"){
                returnObject = {
                    'source': sourceInput.trim(),
                    'target': targetInput.trim(), 
                    'created_timestamp': timeStamp,
                    'last_updated_timestamp': timeStamp, 
                    'deck_id': deck_id, 
                    'id': cardSnapshot !== null ? cardSnapshot.id : null
                };
                Object.entries(o).forEach((entry)=>{
                    returnObject['custom_fields.'+entry[0]] = entry[1];
                });
            } else {
                returnObject = {
                    'source': sourceInput.trim(),
                    'target': targetInput.trim(), 
                    'created_timestamp': timeStamp,
                    'last_updated_timestamp': timeStamp, 
                    'custom_fields': o, 
                    'deck_id': deck_id, 
                    'id': cardSnapshot !== null ? cardSnapshot.id : null
                };
            }
        } else {
            returnObject = {
                'multilingual': true,
                'terms': multilingualTerms,
                'created_timestamp': timeStamp,
                'last_updated_timestamp': timeStamp, 
                'deck_id': deck_id, 
                'id': cardSnapshot !== null ? cardSnapshot.id : null
            };
        }
        return returnObject;
    };
    

    const editItemInDeck = async (deck_id, editCardId, sourceInput, targetInput, customFieldsInput) => {
        if (!sourceInput?.trim() || !targetInput?.trim()) return null;
        const colRef = collection(projectFirestore, "decks");
        const q = query(colRef, where("uid", "==", currentUser.uid), where("id", "==", deck_id));
        const snapshot = await getDocs(q);
        if (snapshot.empty) return null;

        const cardRef = doc(projectFirestore, "deck-cards", editCardId);
        const updatedFields = {
            source: sourceInput.trim(),
            target: targetInput.trim(),
            last_updated_timestamp: serverTimestamp(),
            custom_fields: customFieldsInput,
        };
        await updateDoc(cardRef, updatedFields);
        return true;
    };
    
    const updateMultilingualItemInDeck = async ({cardDocId, multilingualTerms}) => {
        const cardRef = doc(projectFirestore, "deck-cards", cardDocId);
        const updatedFields = {
            terms: multilingualTerms,
            last_updated_timestamp: serverTimestamp(),
        };
        await updateDoc(cardRef, updatedFields);
        return true;
    };

    const fetchAllPublicDecks = async () => {
        const q = query(collection(projectFirestore, "decks"), where("privacy", "==", "public"));
        const snapshot = await getDocs(q);
        return snapshot.docs.map((doc) => doc.data());
    };
    
    const fetchAllPublicDecksData = async () => {
        const decksRef = query(collection(projectFirestore, "decks"), where("privacy", "==", "public"));
        const snapshot = await getDocs(decksRef).catch((err) => {
            saveErrorLog({ errorObject: err, functionName: "fetchAllPublicDecksData" });
            return [];
        });
        const data = snapshot.docs.map((doc) => doc.data());
        return addMissingDeckFields(data);
    };
    
    const renameDeck = async (docId, newName) => {
        const docRef = doc(projectFirestore, "decks", docId);
        const updates = {
            name: newName,
            last_updated_timestamp: serverTimestamp(),
        };
    
        return await updateDoc(docRef, updates).catch((err) => {
            saveErrorLog({ errorObject: err, functionName: "renameDeck" });
            return null;
        });
    };
    
    

    const addMissingDeckFields = (data) => {
        data.forEach((value, index) => {
            if (!('title' in value)){
                data[index]['title'] = 'wow';
            }
            if (!('description' in value)){
                data[index]['description'] = '';
            }
            if (!('author' in value)){
                data[index]['author'] = {
                    'username': '',
                    'displayname': '' 
                };
            }
        }); 
        return data;
    }

    const fetchAllPublicDecksDataFromOneUser = async (uid, limit) => {
        // Build query for fetching public decks of a specific user
        let decksRef;
        const decksCollection = collection(projectFirestore, 'decks');
        
        if (limit !== undefined) {
            // Query with a limit if provided
            decksRef = query(decksCollection, where('privacy', '==', 'public'), where('uid', '==', uid), limit(limit));
        } else {
            // Query without a limit
            decksRef = query(decksCollection, where('privacy', '==', 'public'), where('uid', '==', uid));
        }
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            // Process the documents
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            // Add missing fields to the decks data
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public decks data: ", err);
            return [];
        }
    };

    const fetchAllPublicCommunityDecksData = async () => {
        // Build query to fetch all public community decks except for a specific UID
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('uid', '!=', 'd1AsNHNfQUWYYoplP3UNdKcATss1')
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            // Process the documents
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            // Add missing fields to the decks data
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public community decks data: ", err);
            return [];
        }
    };

    const fetchAllPublicYalangoDecksData = async () => {
        // Build query to fetch all public Yalango decks for a specific UID
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1')
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            // Process the documents
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            // Add missing fields to the decks data
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public Yalango decks data: ", err);
            return [];
        }
    };

    const fetchAllPublicCommunityDecksDataFromOneSourceLanguage = async (source) => {
        const decksRef = query(
            collection(projectFirestore, "decks"),
            where("privacy", "==", "public"),
            where("source_ISO_639-1", "==", source),
            where("uid", "!=", "d1AsNHNfQUWYYoplP3UNdKcATss1")
        );
        const snapshot = await getDocs(decksRef).catch((err) => {
            saveErrorLog({ errorObject: err, functionName: "fetchAllPublicCommunityDecksDataFromOneSourceLanguage" });
            return [];
        });
        const data = snapshot.docs.map((doc) => doc.data());
        return addMissingDeckFields(data);
    };

    const fetchAllPublicYalangoDecksDataFromOneSourceLanguage = async (source) => {
        // Build query to fetch all public Yalango decks for a specific source language and UID
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('source_ISO_639-1', '==', source),
            where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1')
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            // Process the documents
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            // Add missing fields to the decks data
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public Yalango decks data from one source language: ", err);
            return [];
        }
    };

    const fetchAllPublicCommunityDecksDataFromOneTargetLanguage = async (target) => {
        // Build query to fetch all public community decks for a specific target language
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('target_ISO_639-1', '==', target),
            where('uid', '!=', 'd1AsNHNfQUWYYoplP3UNdKcATss1')
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            // Process the documents
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            // Add missing fields to the decks data
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public community decks data from one target language: ", err);
            return [];
        }
    };

    const fetchAllPublicYalangoDecksDataFromOneTargetLanguage = async (target) => {
        // Build query to fetch all public Yalango decks for a specific target language
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('target_ISO_639-1', '==', target),
            where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1')
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            // Process the documents
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            data = addMissingDeckFields(data); // This function is already defined elsewhere
            return data;
        } catch (err) {
            console.error("Error fetching public Yalango decks data from one target language: ", err);
            return [];
        }
    };

    const fetchAllPublicDecksDataFromOneSourceLanguage = async (source) => {
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('source_ISO_639-1', '==', source)
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public decks data from one source language: ", err);
            return [];
        }
    };

    const fetchAllPublicDecksDataFromOneTargetLanguage = async (target, limitValue) => {
        const decksCollection = collection(projectFirestore, 'decks');
        let decksRef;
    
        if (limitValue !== undefined) {
            decksRef = query(
                decksCollection,
                where('privacy', '==', 'public'),
                where('target_ISO_639-1', '==', target),
                limit(limitValue)
            );
        } else {
            decksRef = query(
                decksCollection,
                where('privacy', '==', 'public'),
                where('target_ISO_639-1', '==', target)
            );
        }
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public decks data from one target language: ", err);
            return [];
        }
    };

    const fetchCommunityDecksDataFromOneLanguage = async (target, source) => {
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('target_ISO_639-1', '==', target),
            where('source_ISO_639-1', '==', source),
            where('uid', '!=', 'd1AsNHNfQUWYYoplP3UNdKcATss1')
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching community decks data from one language: ", err);
            return [];
        }
    };
    
    const fetchPublicDecksDataFromOneLanguage = async (target, source) => {
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('target_ISO_639-1', '==', target),
            where('source_ISO_639-1', '==', source)
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching public decks data from one language: ", err);
            return [];
        }
    };
    
    const fetchYalangoDecksDataFromOneLanguage = async (target, source) => {
        const decksCollection = collection(projectFirestore, 'decks');
        const decksRef = query(
            decksCollection,
            where('privacy', '==', 'public'),
            where('target_ISO_639-1', '==', target),
            where('source_ISO_639-1', '==', source),
            where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1')
        );
    
        try {
            const querySnapshot = await getDocs(decksRef);
            let data = [];
            
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
    
            data = addMissingDeckFields(data);
            return data;
        } catch (err) {
            console.error("Error fetching Yalango decks data from one language: ", err);
            return [];
        }
    };
    
    const fetchDeckCustomFieldsFromCurrentUserRealtime = async (docId, setCustomFields) => {
        const customFieldsRef = collection(doc(projectFirestore, 'decks', docId), 'custom-fields');
        
        const unsub = onSnapshot(customFieldsRef, (snap) => {
            if (snap.docs.length > 0) {
                let customFields = [];
                snap.docs.forEach((doc) => {
                    customFields.push({
                        docId: doc.ref.id,
                        data: doc.data()
                    });
                });
                setCustomFields(customFields);
            } else {
                setCustomFields([]);
            }
        });
    
        return unsub;
    };

    const fetchDeckCustomFieldsFromCurrentUser = async (docId) => {
        const customFieldsRef = collection(doc(projectFirestore, 'decks', docId), 'custom-fields');
        const snap = await getDocs(customFieldsRef);
        
        if (snap.docs.length > 0) {
            let customFields = [];
            snap.docs.forEach((doc) => {
                customFields.push({
                    docId: doc.id,
                    data: doc.data()
                });
            });
            return customFields;
        } else {
            return [];
        }
    };

    const saveNewCustomFieldApi = async (deck, name, type) => {
        if (type !== "text" && type !== "list"){return null;}
        
        const func = httpsCallable(projectFunctions, 'saveNewDeckCustomField');
        let results = await func({
            'deck': deck,
            'name': name,
            'type': type
        }); 
        return results.data;
    }

    const deleteCustomFieldApi = async ({deckDocId, fieldDocId}) => {
        console.log(deckDocId, fieldDocId);
        const func = httpsCallable(projectFunctions, 'deleteCustomField');
        let results = await func({
            'fieldDocId': fieldDocId,
            'deckDocId': deckDocId
        });
        return results; 
    }

    const editExistingCustomFieldApi = async (deck, oldField, newField) => {
        if (newField.data.type !== "text" && newField.data.type !== "list"){return null;}
        if (newField.data.name === ""){return null;}

        const func = httpsCallable(projectFunctions, 'editDeckCustomField');
        let results = await func({
            'deck': deck,
            'oldField': oldField,
            'newField': newField
        }); 
        return results.data;
    }
    

    const fetchAllItemsInUserDeckRealtime = async (doc_id, deck_id, setDocuments, pageSize, setFirstVisibleItem, setLastVisibleItem, docStartPoint, type) => {
        const itemsRef = collection(projectFirestore, "decks", doc_id, "items");
        let q;
        if (docStartPoint) {
            if (type === "next") {
                q = query(itemsRef, orderBy("last_updated_timestamp", "desc"), startAfter(docStartPoint), limit(pageSize));
            } else {
                q = query(itemsRef, orderBy("last_updated_timestamp", "desc"), endBefore(docStartPoint), limitToLast(pageSize));
            }
        } else {
            q = query(itemsRef, orderBy("last_updated_timestamp", "desc"), limit(pageSize));
        }
        return onSnapshot(q, (snapshot) => {
            const documents = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, deck_id }));
            const firstVisible = snapshot.docs[0];
            const lastVisible = snapshot.docs[snapshot.docs.length - 1];
            setDocuments(documents);
            setFirstVisibleItem(firstVisible);
            setLastVisibleItem(lastVisible);
        });
    };

    const fetchPaginatedItemsInUserDeck = async (doc_id, deck_id, pageSize, docStartPoint, type, sortByValue, searchTerm) => {
        let deckItemsCollection = collection(projectFirestore, 'decks', doc_id, 'items');
        let q = query(deckItemsCollection);
    
        if (searchTerm) {
            q = query(q, where("target", "==", searchTerm));
        }
    
        if (sortByValue) {
            switch (sortByValue) {
                case 'target_alphabetically':
                    q = query(q, orderBy("target"));
                    break;
                case 'source_alphabetically':
                    q = query(q, orderBy("source"));
                    break;
                case 'last_updated_timestamp_asc':
                    q = query(q, orderBy("last_updated_timestamp"));
                    break;
                case 'last_updated_timestamp_desc':
                    q = query(q, orderBy("last_updated_timestamp", "desc"));
                    break;
                case 'created_timestamp_asc':
                    q = query(q, orderBy("created_timestamp"));
                    break;
                case 'created_timestamp_desc':
                    q = query(q, orderBy("created_timestamp", "desc"));
                    break;
                default:
                    q = query(q, orderBy("last_updated_timestamp", "desc"));
            }
        }
    
        if (docStartPoint) {
            q = type === "next" ? query(q, startAfter(docStartPoint), limit(pageSize)) : query(q, endBefore(docStartPoint), limitToLast(pageSize));
        } else {
            q = query(q, limit(pageSize));
        }
    
        let documents = [];
        let firstVisible = null;
        let lastVisible = null;
    
        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                firstVisible = querySnapshot.docs[0];
                lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc) => {
                    documents.push({ ...doc.data(), id: doc.id, deck_id });
                });
            }
    
            if (searchTerm) {
                const sourceQuery = query(deckItemsCollection, where("source", "==", searchTerm));
                const sourceSnapshot = await getDocs(sourceQuery);
                sourceSnapshot.forEach((doc) => {
                    documents.push({ ...doc.data(), id: doc.id, deck_id });
                });
            }
        } catch (err) {
            console.error("Error fetching paginated items in user deck: ", err);
        }
    
        return { documents, firstVisible, lastVisible };
    };

    const refreshCardInDeck = async (deckDocId, cardDocId) => {
        let query;
        if (newCardStructureBool) {
            query = doc(projectFirestore, "deck-cards", cardDocId);
        } else {
            query = doc(projectFirestore, "decks", deckDocId, "items", cardDocId);
        }
    
        try {
            const snapshot = await getDocs(query);
            if (snapshot.exists()) {
                return snapshot.data();
            }
        } catch (err) {
            console.error("Error refreshing card in deck: ", err);
        }
    
        return null;
    };

    const fetchAllItemsInPublicDeck = async (doc_id, docStartPoint, type) => {
        const pageSize = 24;
        let deckCardsRef = collection(projectFirestore, "deck-cards");
    
        let q = query(
            deckCardsRef,
            where("privacy", "==", "public"),
            where("deck_doc_id", "==", doc_id),
            orderBy("last_updated_timestamp", "desc")
        );
    
        if (docStartPoint) {
            q = type === "next"
                ? query(q, startAfter(docStartPoint), limit(pageSize))
                : query(q, endBefore(docStartPoint), limitToLast(pageSize));
        } else {
            q = query(q, limit(pageSize));
        }
    
        try {
            const querySnapshot = await getDocs(q);
            const documents = [];
            let firstVisible = null;
            let lastVisible = null;
    
            if (!querySnapshot.empty) {
                firstVisible = querySnapshot.docs[0];
                lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc) => {
                    documents.push({ ...doc.data(), id: doc.id });
                });
            }
    
            return [documents, firstVisible, lastVisible];
        } catch (err) {
            console.error("Error fetching items in public deck: ", err);
            return [[], null, null];
        }
    };

    const fetchAllItemsAndMetadataInPublicDeck = async (deck_id, startAfterDoc) => {
        let metadata;
        let firstVisible;
        let lastVisible;
    
        const docRef = query(
            collection(projectFirestore, 'decks'),
            where('privacy', '==', 'public'),
            where('id', '==', deck_id)
        );
    
        try {
            const querySnapshot = await getDocs(docRef);
            if (querySnapshot.empty) return [null, null, null, null];
            
            metadata = querySnapshot.docs[0].data();
            metadata['doc_id'] = querySnapshot.docs[0].id;
    
            const cardQuery = startAfterDoc 
                ? query(
                    collection(projectFirestore, 'deck-cards'),
                    where('privacy', '==', 'public'),
                    where('deck_id', '==', deck_id),
                    orderBy('last_updated_timestamp', 'desc'),
                    startAfter(startAfterDoc),
                    limit(24)
                  )
                : query(
                    collection(projectFirestore, 'deck-cards'),
                    where('privacy', '==', 'public'),
                    where('deck_id', '==', deck_id),
                    orderBy('last_updated_timestamp', 'desc'),
                    limit(24)
                  );
    
            const cardSnapshot = await getDocs(cardQuery);
            const documents = [];
            firstVisible = cardSnapshot.docs[0];
            lastVisible = cardSnapshot.docs[cardSnapshot.docs.length - 1];
    
            cardSnapshot.forEach(doc => {
                documents.push({ ...doc.data(), id: doc.id, deck_id });
            });
    
            return [documents, metadata, firstVisible, lastVisible];
        } catch (err) {
            console.error("Error fetching items and metadata in public deck: ", err);
            return [null, null, null, null];
        }
    };
    
    const fetchDeckSectionsFromCurrentUserInTargetLanguage = async (target, setStateFunc) => {
        const sectionsRef = collection(projectFirestore, 'users', currentUser.uid, 'private-data', 'decks', 'sections');
        const q = query(sectionsRef, where('target_ISO_639-1', '==', target));
    
        const unsub = onSnapshot(q, (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((section) => {
                data.push(section.data());
            });
            setStateFunc(data);
        });
    
        return unsub;
    };

    const swapFavoriteStatusForDeck = async (deck_id) => {
        const collectionRef = collection(projectFirestore, 'users', currentUser.uid, 'private-data', 'decks', 'categories');
        const q = query(collectionRef, where('decks.id', 'array-contains', deck_id));
    
        try {
            const snapshot = await getDocs(q);
            if (snapshot.size === 1) {
                snapshot.forEach((doc) => {
                    const doc_id = doc.id;
                    const old_value = doc.data()?.decks?.favorite?.[deck_id];
                    const updateRef = doc(collectionRef, doc_id);
                    updateDoc(updateRef, {
                        [`decks.favorite.${deck_id}`]: !old_value,
                    });
                });
            }
        } catch (err) {
            console.error("Error swapping favorite status for deck: ", err);
        }
    };
    
    const fetchDecksFromDeckIdsForCurrentUser = async (deck_ids, setStateFunc) => {
        let data = [];
        let l_index = 1;
    
        const fetchDeck = async (deck_id) => {
            const d_query = query(
                collection(projectFirestore, 'decks'),
                where('uid', '==', currentUser.uid),
                where('id', '==', deck_id)
            );
            
            try {
                const querySnapshot = await getDocs(d_query);
                if (querySnapshot.docs.length === 1) {
                    data.push(querySnapshot.docs[0].data());
                }
            } catch (err) {
                console.error("Error fetching deck: ", err);
            }
        };
    
        for (const deck_id of deck_ids) {
            await fetchDeck(deck_id);
            if (l_index === deck_ids.length) {
                setStateFunc(data);
            }
            l_index += 1;
        }
    };

    const fetchAllDecksFromCurrentUser = async () => {
        const decksQuery = query(collection(projectFirestore, "decks"), where("uid", "==", currentUser.uid));
        const snapshot = await getDocs(decksQuery).catch((err) => {
            saveErrorLog({ errorObject: err, functionName: "fetchAllDecksFromCurrentUser" });
            return [];
        });
        return snapshot.docs.map((doc) => ({ ...doc.data(), doc_id: doc.id }));
    };

    const fetchAllDecksFromCurrentUserInRealtime = async (setDecks) => {
        const q = query(collection(projectFirestore, "decks"), where("uid", "==", currentUser.uid));
        return onSnapshot(q, (snapshot) => {
            const decks = snapshot.docs.map((doc) => ({ ...doc.data(), doc_id: doc.id }));
            setDecks(decks);
        });
    };

    const fetchAllDecksFromCurrentUserInTargetLanguage = async (target_language) => {
        const q = query(
            collection(projectFirestore, 'decks'),
            where('uid', '==', currentUser.uid),
            where('target_ISO_639-1', '==', target_language)
        );
    
        const decks = [];
        try {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                decks.push(doc.data());
            });
        } catch (err) {
            console.error("Error fetching decks: ", err);
        }
        return decks;
    };
    
    const fetchAllDecksFromCurrentUserInTargetLanguageInRealtime = async (target_language, setStateFunc, sortByValue) => {
        let queryRef = collection(projectFirestore, 'decks');
        queryRef = query(queryRef, where('uid', '==', currentUser.uid), where('target_ISO_639-1', '==', target_language));
    
        if (sortByValue) {
            switch (sortByValue) {
                case "last_updated_timestamp_desc":
                    queryRef = query(queryRef, orderBy('last_updated_timestamp', 'desc'));
                    break;
                case "last_updated_timestamp_asc":
                    queryRef = query(queryRef, orderBy('last_updated_timestamp'));
                    break;
                case "alphabetically":
                    queryRef = query(queryRef, orderBy('name'));
                    break;
                case "created_timestamp_asc":
                    queryRef = query(queryRef, orderBy('created_timestamp'));
                    break;
                case "created_timestamp_desc":
                    queryRef = query(queryRef, orderBy('created_timestamp', 'desc'));
                    break;
                case "number_of_items":
                    queryRef = query(queryRef, orderBy('number_of_items', 'desc'));
                    break;
                default:
                    break;
            }
        } else {
            queryRef = query(queryRef, orderBy('last_updated_timestamp', 'desc'));
        }
    
        const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
            const decks = [];
            querySnapshot.forEach((doc) => {
                decks.push(doc.data());
            });
            setStateFunc(decks);
        });
    
        return unsubscribe;
    };

    const fetchAllCategoriesFromCurrentUserInTargetLanguageInRealtime = async (target_language, setStateFunc) => {
        const colRef = collection(
            getFirestore(),
            "users",
            currentUser.uid,
            "private-data",
            "decks",
            "categories"
        );
        const q = query(colRef, where("target_ISO_639-1", "==", target_language));
    
        const unsub = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => doc.data());
            setStateFunc(data);
        });
    
        return unsub;
    };

    const handleAddDeckForCurrentUser = async (name, category_id, source_language, target_language) => {
        const collectionRef = collection(projectFirestore, "decks");
        let userdata = await fetchCurrentUserInfo();
        
        if (userdata === null) {
            userdata = { author: { username: null, displayname: "" } };
        }
    
        const author = userdata.author || { username: null, displayname: "" };
        
        if (name.length > 0) {
            const generateDeckId = httpsCallable(projectFunctions, "generateDeckId");
            const results = await generateDeckId();
            const id = results.data;
    
            await addDoc(collectionRef, {
                name,
                uid: currentUser.uid,
                id,
                typesense_deck_id: id,
                "source_ISO_639-1": source_language,
                "target_ISO_639-1": target_language,
                privacy: "private",
                number_of_items: 0,
                description: "",
                multilingual: false,
                title: "",
                tags: "",
                items_doc_ids: [],
                created_timestamp: timeStamp,
                last_updated_timestamp: timeStamp,
                author: {
                    username: author.username,
                    displayname: author.displayname
                }
            });
    
            addDeckIdToCategoryForCurrentUser(id, category_id);
            return id;
        }
    };

    const handleAddMultilingualDeck = async ({name, privacy}) => {
        const collectionRef = collection(projectFirestore, "decks");
        let userdata = await fetchCurrentUserInfo();
        let authorMetadata = {};
    
        if (!userdata) {
            authorMetadata.username = null;
            authorMetadata.displayname = "";
        } else {
            authorMetadata.displayname = userdata.displayname || "";
            authorMetadata.username = userdata.username || "";
        }
    
        
        if (name.length > 0) {
            const generateDeckId = httpsCallable(projectFunctions, "generateDeckId"); //multilingual decks are decks under the hood but with a different structure
            const results = await generateDeckId();
            const id = results.data;
            const obj = {
                name,
                uid: currentUser.uid,
                id,
                typesense_deck_id: id,
                privacy,
                number_of_items: 0,
                description: null,
                multilingual: true,
                title: null,
                created_timestamp: timeStamp,
                last_updated_timestamp: timeStamp,
                author: {
                    username: authorMetadata.username,
                    displayname: authorMetadata.displayname,
                },
            };
            let snapshot = await addDoc(collectionRef, obj).catch(() => false);
            return id;
        }
        else {
            return false;
        }
    
    };

    const handleAddDeckForCurrentUserWithoutCategory = async (name, source_language, target_language, privacy, type, parentFolderDocId, targetScript, sourceScript) => {
        const collectionRef = collection(projectFirestore, "decks");
        let userdata = await fetchCurrentUserInfo();
        let authorMetadata = {};
    
        if (!userdata) {
            authorMetadata.username = null;
            authorMetadata.displayname = "";
        } else {
            authorMetadata.displayname = userdata.displayname || "";
            authorMetadata.username = userdata.username || "";
        }
    
        if (name.length > 0) {
            const generateDeckId = httpsCallable(projectFunctions, "generateDeckId");
            const results = await generateDeckId();
            const id = results.data;
    
            let obj = {
                name,
                uid: currentUser.uid,
                id,
                typesense_deck_id: id,
                "source_ISO_639-1": source_language,
                "target_ISO_639-1": target_language,
                number_of_items: 0,
                privacy,
                description: "",
                title: "",
                tags: "",
                created_timestamp: timeStamp,
                last_updated_timestamp: timeStamp,
                author: {
                    username: authorMetadata.username,
                    displayname: authorMetadata.displayname,
                },
            };
    
            if (targetScript) {
                obj.target_script = targetScript;
            }
            if (sourceScript) {
                obj.source_script = sourceScript;
            }
    
            let snapshot = await addDoc(collectionRef, obj).catch(() => false);
    
            if (type === "subitem" && snapshot) {
                let f = httpsCallable(projectFunctions, "moveItemToFolder");
                await f({
                    item: {
                        doc_id: snapshot.id,
                        content_type: "deck",
                    },
                    destinationFolder: parentFolderDocId,
                    currentParent: "top_level",
                });
            }
    
            return id;
        }
    };
    
    const editDeckNameForCurrentUser = async (deck_id, new_name) => {
        if (new_name.length > 0) {
            const collectionRef = collection(projectFirestore, "decks");
            const docRef = collectionRef.where("uid", "==", currentUser.uid).where("id", "==", deck_id);
            const snapshots = await docRef.get();
    
            if (snapshots.size === 1) {
                snapshots.forEach(async snapshot => {
                    const doc_id = snapshot.id;
                    const updateRef = doc(projectFirestore, "decks", doc_id);
                    await updateDoc(updateRef, {
                        name: new_name,
                        last_updated_timestamp: timeStamp,
                    });
                });
            }
    
            return true;
        }
    };

    const deleteDeck = async (deck_id) => {
        if (!currentUser) return false;
        const colRef = collection(projectFirestore, "decks");
        const q = query(colRef, where("uid", "==", currentUser.uid), where("id", "==", deck_id));
        const snapshot = await getDocs(q);
        if (snapshot.empty) return false;
        const docId = snapshot.docs[0].id;
        await deleteDoc(doc(projectFirestore, "decks", docId));
        return true;
    };

    const deleteDeckFromDocId = async (deckDocId) => {
        if (currentUser === null) {
            return false;
        }
        const docRef = doc(projectFirestore, "decks", deckDocId);
        await deleteDoc(docRef);
    };

    const duplicateDeckApi = async (deck_id) => {
        const duplicateDeckFunc = httpsCallable(projectFunctions, 'duplicateDeck');
        let results = await duplicateDeckFunc({'deck_id': deck_id});
        return results;    
    }

    const duplicateDeckToMyLibraryApi = async (deck_id, name) => {
        const addDeckToMyLibraryFunc = httpsCallable(projectFunctions, 'addDeckToMyLibrary');
        return addDeckToMyLibraryFunc({'deck_id': deck_id, 'name': name});
    }

    const fetchDeckFromCurrentUser = async (deck_id) => {
        const q = query(collection(projectFirestore, "decks"), where("uid", "==", currentUser.uid), where("id", "==", deck_id));
        const snapshot = await getDocs(q);
        if (snapshot.empty) return null;
        return { ...snapshot.docs[0].data(), doc_id: snapshot.docs[0].id };
    };

    const fetchPublicDeck = async (deck_id) => {
        const deckRef = collection(projectFirestore, "decks");
        const q = query(deckRef, where("privacy", "==", "public"), where("id", "==", deck_id));
        const snapshot = await getDocs(q);
        if (snapshot.empty) {
            return null;
        }
        const data = snapshot.docs[0].data();
        data['doc_id'] = snapshot.docs[0].id;
        return data;
    };

    const fetchDeckFromCurrentUserRealtime = async (deck_id, setDeck, setError) => {
        const q = query(collection(projectFirestore, "decks"), where("uid", "==", currentUser.uid), where("id", "==", deck_id));
        return onSnapshot(q, (snapshot) => {
            if (snapshot.empty) setError(true);
            else setDeck({ ...snapshot.docs[0].data(), doc_id: snapshot.docs[0].id });
        });
    };

    const duplicateDeck = async (deck_id) => {
        if (!currentUser) {
            return false;
        }
    
        const collectionRef = collection(projectFirestore, "decks");
        const docRef = query(collectionRef, where('uid', '==', currentUser.uid), where('id', '==', deck_id));
        let doc_ref_id;
        let deck_data = await getDocs(docRef).then(snapshot => {
            doc_ref_id = snapshot.docs[0].id;
            return snapshot.docs[0].data();
        });
    
        let query;
        if (newCardStructureBool) {
            query = collection(projectFirestore, "deck-cards");
        } else {
            query = doc(collectionRef, doc_ref_id).collection('items');
        }
    
        let items = await getDocs(query).then(snapshot => {
            let d = [];
            if (!snapshot.empty) {
                snapshot.docs.forEach(i => {
                    let i_data = i.data();
                    i_data['created_timestamp'] = timeStamp;
                    i_data['last_updated_timestamp'] = timeStamp;
                    d.push(i_data);
                });
            }
            return d;
        });
    
        let new_doc_ref_id = await addDuplicatedDeck(deck_data);
        return addItemsToDeck(new_doc_ref_id, items);
    };

    const addDuplicatedDeck = async (data) => {
        const collectionRef = collection(projectFirestore, 'decks');
        const generateDeckId = httpsCallable(projectFunctions, 'generateDeckId');
        const results = await generateDeckId();
        let id = results.data;
        data.id = id;
        data.name = "(DUPLICATE) " + data.name;
        data.last_updated_timestamp = timeStamp;
        const created_doc = await addDoc(collectionRef, data);
        return created_doc.id;
    };

    const addItemsToDeck = async (doc_ref_id, items) => {
        if (items.length > 0) {
            for (const item of items) {
                let query = null;
                if (newCardStructureBool) {
                    query = collection(projectFirestore, "deck-cards");
                } else {
                    query = collection(doc(projectFirestore, "decks", doc_ref_id), 'items');
                }
                await addDoc(query, item);
            }
        }
        return true;
    };

    const fetchVocabularyMetadataInTargetLanguage = async (target) => {
        const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "vocabulary", "languages", target, "metadata");
        let d = {
            'items_doc_ids': [],
            'number_of_items': 0, 
            'active_items_doc_ids': [],
            'deleted_items_doc_ids': [],
            'number_of_active_items': 0, 
            'number_of_deleted_items': 0
        };
        const snapshots = await getDocs(colRef);
        snapshots.docs.forEach((doc)=>{
            let data = doc.data();
            if ('items_doc_ids' in data) {
                d['items_doc_ids'] = [...d['items_doc_ids'], ...data['items_doc_ids']];
            }
            if ('number_of_items' in data) {
                d['number_of_items'] += data['number_of_items'];
            }
            if ('active_items_doc_ids' in data) {
                d['active_items_doc_ids'] = [...d['active_items_doc_ids'], ...data['active_items_doc_ids']];
            }
            if ('deleted_items_doc_ids' in data) {
                d['deleted_items_doc_ids'] = [...d['deleted_items_doc_ids'], ...data['deleted_items_doc_ids']];
            }
            if ('number_of_active_items' in data) {
                d['number_of_active_items'] += data['number_of_active_items'];
            }
            if ('number_of_deleted_items' in data) {
                d['number_of_deleted_items'] += data['number_of_deleted_items'];
        }
    });

        return d;
    };

    const fetchVocabularyInTargetLanguage = async (target, filter, itemsPerPage, docStartPoint, type) => {
        let colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "vocabulary", "languages", target, "items");
        let limitItems = itemsPerPage;
        let firstVisible = null;
        let lastVisible = null;
    
        if (filter === "best") {
            colRef = query(colRef, orderBy("total_statistics.percentage.correct", "desc"), orderBy("total_statistics.number_of_answers", "desc"));
        } else if (filter === "worst") {
            colRef = query(colRef, orderBy("total_statistics.percentage.correct"), orderBy("total_statistics.number_of_answers", "asc"));
        }
    
        if (docStartPoint !== undefined && type !== undefined) {
            if (type === "next") {
                colRef = query(colRef, startAfter(docStartPoint), limit(limitItems));
            } else if (type === "last") {
                colRef = query(colRef, endBefore(docStartPoint), limitToLast(limitItems));
            }
        } else {
            colRef = query(colRef, limit(limitItems));
        }
    
        let vocabulary = [];
        let numberOfAllSourcesDeletedItems = 0;
    
        const snapshot = await getDocs(colRef);
        if (!snapshot.empty) {
            firstVisible = snapshot.docs[0];
            lastVisible = snapshot.docs[snapshot.docs.length - 1];
            snapshot.docs.forEach((doc) => {
                let docData = doc.data();
                if (!(docData.hasOwnProperty('all_sources_deleted') && docData['all_sources_deleted'] === true)) {
                    vocabulary.push(docData);
                } else {
                    numberOfAllSourcesDeletedItems += 1;
                }
            });
        }
    
        return { vocabulary, firstVisible, lastVisible, numberOfAllSourcesDeletedItems };
    };
    

    const moveDeck = async (newCategory, filteredCategories, deckId) => {
        for (const cat of filteredCategories){
            if (cat['decks'] !== undefined && cat['decks']['id'].includes(deckId)){
                console.log(cat);
                deleteDeckIdFromCategoryForCurrentUser(deckId, cat['category_id']);
            }
        }
        await addDeckIdToCategoryForCurrentUser(deckId, newCategory);
        return true;
    }

    const fetchSpecificCardsFromDecks = async (deck, chosenCards) => {
        let items = [];
        for (const card of chosenCards) {
            let cardRef = doc(projectFirestore, 'decks', card['deck_doc_id'], 'items', card['card_doc_id']);
            let cardSnapshot = await getDoc(cardRef).catch((err) => { console.log(err); });
            if (cardSnapshot.exists()) {
                let data = cardSnapshot.data();
                data['id'] = cardSnapshot.id;
                data['deck_id'] = deck.id;
                items.push(data);
            }
        }
        return items;
    };

    const saveChangesToDeck = async ({ deckId, deckName, languages, sourceLanguage, privacy, publicTitle, publicDescription, youtubeVideoId, textId, targetLanguage, sourceLanguageScript, targetLanguageScript }) => {
        if (!deckId || !currentUser) return false;
        const q = query(collection(projectFirestore, "decks"), where("uid", "==", currentUser.uid), where("id", "==", deckId));
        const snapshot = await getDocs(q);
        if (snapshot.empty) return false;

        const docId = snapshot.docs[0].id;
        const updateRef = doc(projectFirestore, "decks", docId);
        const updatedFields = {
            last_updated_timestamp: serverTimestamp(),
            ...(deckName && { name: deckName }),
            ...(sourceLanguage && { "source_ISO_639-1": sourceLanguage }),
            ...(targetLanguage && { "target_ISO_639-1": targetLanguage }),
            ...(targetLanguageScript && { target_script: targetLanguageScript }),
            ...(sourceLanguageScript && { source_script: sourceLanguageScript }),
            ...(privacy && { privacy }),
            ...(publicTitle && { title: publicTitle.trim() }),
            ...(publicDescription && { description: publicDescription.trim() }),
            ...(youtubeVideoId && { youtube_id: youtubeVideoId.trim() }),
            ...(textId && { yalango_text_id: textId.trim() }),
            ...(languages && { languages: languages }),
        };
        await updateDoc(updateRef, updatedFields);
        return true;
    };

    const saveChangesToDeckCard = async ({ sourceWord, targetWord, cardDocId }) => {
        if (cardDocId === undefined || currentUser === null) { return false; }
        if (sourceWord === "" && targetWord === "") { return false; }
        console.log(sourceWord, targetWord, cardDocId);
    
        const docRef = doc(projectFirestore, 'deck-cards', cardDocId);
        let obj = {
            'last_updated_timestamp': timeStamp,
        };
    
        if (sourceWord !== undefined && sourceWord !== null && sourceWord !== "") {
            obj['source'] = sourceWord;
        }
        if (targetWord !== undefined && targetWord !== null && targetWord !== "") {
            obj['target'] = targetWord;
        }
    
        console.log("Updating ", obj);
        try {
            await updateDoc(docRef, obj);
            console.log("Success");
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const deleteDeckCard = async ({ cardDocId }) => {
        if (!cardDocId) return false;
        const cardRef = doc(projectFirestore, "deck-cards", cardDocId);
        await deleteDoc(cardRef);
        return true;
    };

    return {deleteDeck, 
        duplicateDeckApi, 
        duplicateDeck, 
        duplicateDeckToMyLibraryApi,
        fetchAllDecksFromCurrentUserInTargetLanguageInRealtime, 
        handleAddDeckForCurrentUserWithoutCategory, 
        fetchAllDecksFromCurrentUser, 
        fetchAllDecksFromCurrentUserInTargetLanguage,
        fetchDecksFromDeckIdsForCurrentUser, 
        editDeckNameForCurrentUser, 
        handleAddDeckForCurrentUser, 
        swapFavoriteStatusForDeck, 
        fetchDeckSectionsFromCurrentUserInTargetLanguage, 
        fetchAllPublicDecksDataFromOneUser, 
        fetchAllPublicCommunityDecksDataFromOneSourceLanguage, 
        fetchAllPublicCommunityDecksDataFromOneTargetLanguage, 
        fetchAllPublicCommunityDecksData, 
        fetchCommunityDecksDataFromOneLanguage, 
        fetchAllPublicDecksDataFromOneSourceLanguage, 
        fetchAllPublicDecksDataFromOneTargetLanguage, 
        fetchAllPublicYalangoDecksDataFromOneTargetLanguage, 
        fetchAllPublicYalangoDecksDataFromOneSourceLanguage, 
        fetchAllPublicYalangoDecksData, 
        fetchYalangoDecksDataFromOneLanguage, 
        fetchPublicDecksDataFromOneLanguage, 
        fetchAllPublicDecksData, 
        fetchAllItemsAndMetadataInPublicDeck, 
        fetchAllItemsInPublicDeck, 
        fetchAllPublicDecks, 
        fetchAllItemsInSingleDeck, 
        addItemToDeck, 
        editItemInDeck, 
        fetchAllCategoriesFromCurrentUserInTargetLanguageInRealtime, 
        moveDeck, 
        fetchVocabularyInTargetLanguage, 
        fetchVocabularyMetadataInTargetLanguage,
        fetchAllItemsInUserDeckRealtime, 
        fetchDeckFromCurrentUser, 
        fetchDeckFromCurrentUserRealtime, 
        fetchDeckCustomFieldsFromCurrentUserRealtime, 
        saveNewCustomFieldApi, 
        editExistingCustomFieldApi, 
        fetchDeckCustomFieldsFromCurrentUser, 
        fetchSpecificCardsFromDecks, 
        getDeckAndXRandomCardsFromDeckApi, 
        getObjectAndXRandomCardsFromTypeApi, 
        refreshObjectAndXRandomCardsFromTypeApi, 
        loadAllGameData, 
        refreshAllGameData, 
        fetchAllDecksFromCurrentUserInRealtime, 
        fetchPaginatedItemsInUserDeck, 
        refreshCardInDeck, 
        renameDeck, 
        deleteDeckFromDocId, 
        fetchPublicDeck, 
        uploadMultipleCardsToDeckApi, 
        saveChangesToDeck, 
        deleteCustomFieldApi, 
        saveChangesToDeckCard, 
        deleteDeckCard, 
        getXMistakesToReviewFromLastYDaysApi,
        handleAddMultilingualDeck, 
        updateMultilingualItemInDeck, 
        uploadDeckThumbnail,
        getAllMultilingualDecksFromUser
    };

}