import React, { useCallback } from 'react';
import {projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";

const useCollections = () => {

    const returnSuccess = ({message, data}) => {
        return {
            success: true,
            message: message, 
            data: data
        }
    }

    const returnError = ({message}) => {
        return {
            success: false,
            message: message
        }
    }

    const addNewCollection = async ({title, slug, languages, display}) => {
        //first check if the collection already exists by verifying slug
        const collectionRef = collection(projectFirestore, "language_collections");
        const q = query(collectionRef, where("slug", "==", slug));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size > 0) {
            console.log("Collection already exists");
            return returnError({message: "Collection already exists"});
        }

        // find next order number (max + 1)
        const q2 = query(collectionRef, orderBy("order", "desc"), limit(1));
        const querySnapshot2 = await getDocs(q2);
        let maxOrder = 0;
        querySnapshot2.forEach((doc) => {
            maxOrder = doc.data().order;
        });

        //if collection does not exist, add it
        const newCollection = {
            title: title,
            slug: slug,
            languages: languages,
            decks: [],
            multilingual: true,
            order: maxOrder+1,
            display: display,
        }

        const docRef = await addDoc(collectionRef, newCollection);
        console.log("Document written with ID: ", docRef.id);
        //append doc Id as well
        newCollection["docId"] = docRef.id;
        return returnSuccess({message: "Collection added", data: newCollection});
    }

    const fetchCollectionByDocId = async ({docId}) => {
        const docRef = doc(projectFirestore, "language_collections", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return returnSuccess({message: "Collection found", data: {...docSnap.data(), docId: docSnap.id}});
        } else {
            console.log("No such document!");
            return returnError({message: "Collection not found"});
        }
    }

    const fetchCollectionByDocIdInRealtime = async ({docId, setCollection, setError}) => {
        const docRef = doc(projectFirestore, "language_collections", docId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                console.log("Document data:", doc.data());
                setCollection({...doc.data(), docId: doc.id});
            } else {
                console.log("No such document!");
                setError("Collection not found");
            }
        });
        return unsubscribe;
    }

    const saveChangesToCollection = async ({display=null, title=null, order=null, docId=null, slug=null, languages=null, decks=null}) => {
        if (docId === null) {
            return returnError({message: "No collection ID provided"});
        }
        const docRef = doc(projectFirestore, "language_collections", docId);
        // only update values that are passed into the function
        let updateObj = {};
        if (display !== null) {
            updateObj["display"] = display;
        }
        if (title !== null) {
            updateObj["title"] = title;
        }
        if (slug !== null) {
            updateObj["slug"] = slug;
        }
        if (languages !== null) {
            updateObj["languages"] = languages;
        }
        if (decks !== null) {
            updateObj["decks"] = decks;
        }
        if (order !== null) {
            updateObj["order"] = Number(order);
        }

        console.log("updateObj: ", updateObj);
        await updateDoc(docRef, updateObj);
        return returnSuccess({message: "Collection updated"});
    }

    const renameLanguageCollection = useCallback(async (docId, title) => {
        //use saveChangesToCollection
        return await saveChangesToCollection({title: title, docId: docId});
    },[]);

    const fetchAllLanguageCollectionsInRealtime = async ({setCollections, setError}) => {
        const collectionRef = collection(projectFirestore, "language_collections");
        const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
            let collections = [];
            querySnapshot.forEach((doc) => {
                collections.push({...doc.data(), docId: doc.id});
            });
            setCollections(collections);
        }, (error) => {
            setError("Error getting documents: " + error);
        });
        return unsubscribe;
    }

    return {
        addNewCollection, 
        fetchCollectionByDocId, 
        saveChangesToCollection, 
        fetchCollectionByDocIdInRealtime, 
        fetchAllLanguageCollectionsInRealtime, 
        renameLanguageCollection
    }

}

export default useCollections;