import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { DebounceInput } from 'react-debounce-input';
import useCollections from '../../hooks/useCollections';

const CollectionOrder = ({ collection }) => {
    console.log("collection: ", collection);
    const [saving, setSaving] = useState(false);
    const { saveChangesToCollection } = useCollections();

    const updateCollectionOrder = async (e) => {
        const value = e.target.value;
        if (!isNaN(value) && Number(value) >= 0) {
            setSaving(true);
            await saveChangesToCollection({ order: Number(value), docId: collection.docId });
            setSaving(false);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="pl-2 flex w-full flex-col gap-4 justify-start place-items-start">
                <div className="flex flex-row gap-4 justify-start place-items-center">
                    <div className="italic font-semibold">
                        Order:
                    </div>
                    <div className="font-bold muted-text text-lg">
                        {collection !== null ? (
                            <DebounceInput
                                className="text-left dark:text-white hover:underline bg-transparent font-semibold border-none outline-none"
                                minLength={1}
                                value={collection.order}
                                debounceTimeout={1000}
                                onChange={updateCollectionOrder}
                                type="number"
                                min="0"
                            />
                        ) : (
                            <span className="text-gray-500">Loading...</span>
                        )}
                    </div>
                    <div>
                        {saving && <FontAwesomeIcon icon={faSpinner} spin />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionOrder;
